<template>
  <div v-if="MODULE_PERMISSIONS(['MODULE_STORAGE'] , true)">

    <slot v-if="!noActivator" name="default" :select="openDialog">
      <v-btn @click="openDialog" :outlined="!text" :text="text" :block="block" :color="wsACCENT" class="noCaps">
        {{ $t('ChooseFromStorage') }}
        <v-icon class="ml-1">mdi-magnify</v-icon>
      </v-btn>
    </slot>

    <ws-dialog
        width="800"
        v-model="displayDialog"
        :title="$t('ChooseFile')"
        :show-save="false"
        height="100%"
        not-centered
    >
      <div class="d-flex align-center">
        <ws-text-field
            @input="handleSearchCancel"
            v-model="search"
            width="100%"
            icon="mdi-magnify"
            class="mr-2"
            clearable
        />
        <ws-button
            @click="getStorage(false)"
            label="Search"
            :disabled="LOADING"
        />
      </div>
      <ws-data-table
          class="mt-5"
          :items="itemsFiltered"
          :headers="headers"
          :search="!ajaxSearchIsOn ? search : null"
          dense
          :row-action="selectFile"
          :total="total"
          :load-more="loadMore"
      >

        <template  #header.after="{colspanValue}">
          <v-hover v-if="selectedFolder" #default="{hover}">
            <tr @click="goToPreviousFolder">
              <td class="pointer" :colspan="colspanValue"
                  :style="`background : ${hover ? wsLIGHTCARD : 'transparent'};`"
                  style="border-bottom : 1px solid var(--wsBACKGROUND)"
              >
                <h5>
                  <v-icon :color="wsATTENTION"> mdi-folder-outline </v-icon>
                  ...
                </h5>
              </td>
            </tr>
          </v-hover>

          <v-hover v-if="!selectedFolder && !search && !MODULE_PERMISSIONS(['MODULE_STORAGE__FULL_ACCESS'] , true)" #default="{hover}">
            <tr @click="openFolder({ uuid : 'root' , name : $t('file_browser.shared_folder')} , true)">
              <td class="pointer" :colspan="colspanValue"
                  :style="`background : ${hover ? wsLIGHTCARD : 'transparent'};`"
                  style="border-bottom : 1px solid var(--wsBACKGROUND)"
              >
                <h5>
                  <v-icon :color="wsACCENT"> mdi-folder-account </v-icon>
                  {{  $t('file_browser.shared_folder')  }}
                </h5>
              </td>
            </tr>
          </v-hover>



        </template>


        <template #item.name="{item}">
          <h5>
            <v-icon :color="wsATTENTION">{{ getIcon(item.mime) }}</v-icon>
            {{ SHORTEN_FILE_NAME(item.name) }}
          </h5>
        </template>

        <template #item.size="{item}">
          <div>
            <h5 v-if="item.mime !== 'folder'">{{ PARSE_FILE_SIZE(item.size)    }}</h5>
          </div>
        </template>

        <template #item.relations_count="{item}">
          <div>
            <h5 class="text-center" v-if="item.relations_count > 0"> {{ item.relations_count   }}</h5>
          </div>
        </template>

        <template #item.date_added="{item}">
          <h5 class="font-weight-medium"> {{ PARSE_DATE(item.date_added*1000 ) }}</h5>
        </template>

      </ws-data-table>

    </ws-dialog>

  </div>
</template>

<script>
import {mapActions} from "vuex";

export default {
  name: "wsStorageFilePicker",
  props : {
    value : {
      default : null
    },
    video : {
      type : Boolean,
      default : false
    },
    image : {
      type : Boolean,
      default : false
    },
    block : {
      type : Boolean,
      default : false
    },
    text : {
      type : Boolean,
      default : false
    },
    trigger : {
      type : Number,
    },
    noActivator : {
      type : Boolean,
      default : false
    },
    public : {
      type : Boolean,
      default : false
    },
    formats : {
      type : Array,
      default() { return [] }
    }
  },
  data() {
    return {
      search: '',
      displayDialog : false,
      file : null,
      items : [],
      total : 0,
      initialItems : 0,
      clearTimeout : null,
      isDisplayingSearchResult : false,

      selectedFolder : null,
      location : [],
      isSharedFolderSelected : false
    }
  },
  computed : {
    ajaxSearchIsOn() {
      return this.initialItems > 100
    },
    itemsFiltered() {
      let items = this.items
      if ( items.length === 0 ) {
        return []
      }

      if ( this.formats.length > 0 ) {
        items = items.filter(el=> this.formats.includes(el.mime.split('/')[1]) )
      }

      if ( this.public ) {
        items = items.filter(el=>el.url && el.url !== 'google' )
      }

      if ( this.video ) {
        items = items.filter(el=>el.mime.includes('video') || el.mime === 'folder' )
      }
      if ( this.image ) {
        items = items.filter(el=>el.mime.includes('image') || el.mime === 'folder' )
      }

      return items
    },
    headers() {
      return [
        { text : this.$t('Name') , value : 'name' },
        { text : this.$t('DateCreated') , value : 'date_added' },
        { text : this.$t('FileSize') , value : 'size' },
      ]
    },
    ajaxFiltersInput() {
      let data = {}
      if ( this.video) {
        data.mime = 'video'
      }
      if ( this.image) {
        data.mime = 'image'
      }
      if ( this.search) {
        data.search = this.search
      }
      if ( this.selectedFolder) {
        data.parent = this.selectedFolder
      }
      if (this.isSharedFolderSelected) {
        data.is_shared = true
      }
      return data
    }
  },
  watch : {
    trigger() {
      this.openDialog()
    },
    value : {
      handler() {
        if ( JSON.stringify(this.value) !== JSON.stringify(this.file) ) {
          this.file = Object.assign({} , this.value)
        }
      },
      deep : true
    },
    file : {
      handler() {
        if ( JSON.stringify(this.value) !== JSON.stringify(this.file) ) {
          this.$emit('input' , Object.assign({} , this.file) )
        }
      },
      deep : true
    },
  },
  methods : {
    ...mapActions('storage', [ 'GET_STORAGE_BROWSER' ]),

    handleSearchCancel(value) {
      if ( !value ) {
        this.clearTimeout = setTimeout(  this.getStorage, 250)
      } else if (this.clearTimeout) {
        clearTimeout( this.clearTimeout)
      }
    },
    selectFile(item) {
      if ( item.mime === 'folder') {
        this.openFolder(item , this.isSharedFolderSelected)
        return
      }
      this.file = item
      this.displayDialog = false
    },
    getIcon(mime) {

      if (!mime ) {
        return 'mdi-file-outline'
      }

      if ( mime.includes('image') ) {
        return 'mdi-file-image-outline'
      }

      if ( mime.includes('video') ) {
        return 'mdi-file-video-outline'
      }
      if ( mime === 'folder') {
        return 'mdi-folder'
      }

      return 'mdi-file-outline'

    },

    goToPreviousFolder() {
      this.search = null
      this.location.pop()
      if ( this.location.length === 0) {
        this.isSharedFolderSelected = false
        this.selectedFolder = null
      } else {
        this.selectedFolder = this.location[this.location.length - 1].uuid
        this.isSharedFolderSelected = this.location[this.location.length - 1].shared
      }
      this.getStorage(true)
    },
    openFolder(folder , shared = false) {
      this.search = null
      this.selectedFolder = folder.uuid
      this.isSharedFolderSelected = shared
      this.location.push(
          { name: folder.name, uuid : folder.uuid , shared : shared }
      )
      this.getStorage(true)
    },
    openDialog() {
      this.search = null
      this.selectedFolder = null
      this.items = []
      this.getStorage()
      this.displayDialog = true
      this.isSharedFolderSelected = false
    },

    async loadMore(offset , itemPerPage) {

      if ( (offset + 1) > this.items.length && (offset < this.total) ) {

        let data = this.COPY(this.ajaxFiltersInput)
        data.offset = offset
        data.limit = itemPerPage

        let result = await this.GET_STORAGE_BROWSER(data)

        if ( !result ) {
          this.loading = false
          return
        }
        this.items = [...this.items , ...result.items]
      }

    },

    async getStorage(initial = true) {
      this.items = []
      let result = await this.GET_STORAGE_BROWSER(this.ajaxFiltersInput)
      if ( !result ) {
        return
      }
      this.items = result.items
      this.total = result.total
      if ( initial) {
        this.initialItems = result.total
        this.isDisplayingSearchResult = false
      } else {
        this.isDisplayingSearchResult = true
      }
    }
  },
  mounted() {
    if ( this.value ) {
      this.file = Object.assign({} , this.value)
    }

    this.displayDialog = this.display
  }
}
</script>

<style scoped>

</style>