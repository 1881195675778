var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.MODULE_PERMISSIONS(['MODULE_STORAGE'] , true))?_c('div',[(!_vm.noActivator)?_vm._t("default",[_c('v-btn',{staticClass:"noCaps",attrs:{"outlined":!_vm.text,"text":_vm.text,"block":_vm.block,"color":_vm.wsACCENT},on:{"click":_vm.openDialog}},[_vm._v(" "+_vm._s(_vm.$t('ChooseFromStorage'))+" "),_c('v-icon',{staticClass:"ml-1"},[_vm._v("mdi-magnify")])],1)],{"select":_vm.openDialog}):_vm._e(),_c('ws-dialog',{attrs:{"width":"800","title":_vm.$t('ChooseFile'),"show-save":false,"height":"100%","not-centered":""},model:{value:(_vm.displayDialog),callback:function ($$v) {_vm.displayDialog=$$v},expression:"displayDialog"}},[_c('div',{staticClass:"d-flex align-center"},[_c('ws-text-field',{staticClass:"mr-2",attrs:{"width":"100%","icon":"mdi-magnify","clearable":""},on:{"input":_vm.handleSearchCancel},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('ws-button',{attrs:{"label":"Search","disabled":_vm.LOADING},on:{"click":function($event){return _vm.getStorage(false)}}})],1),_c('ws-data-table',{staticClass:"mt-5",attrs:{"items":_vm.itemsFiltered,"headers":_vm.headers,"search":!_vm.ajaxSearchIsOn ? _vm.search : null,"dense":"","row-action":_vm.selectFile,"total":_vm.total,"load-more":_vm.loadMore},scopedSlots:_vm._u([{key:"header.after",fn:function(ref){
var colspanValue = ref.colspanValue;
return [(_vm.selectedFolder)?_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('tr',{on:{"click":_vm.goToPreviousFolder}},[_c('td',{staticClass:"pointer",staticStyle:{"border-bottom":"1px solid var(--wsBACKGROUND)"},style:(("background : " + (hover ? _vm.wsLIGHTCARD : 'transparent') + ";")),attrs:{"colspan":colspanValue}},[_c('h5',[_c('v-icon',{attrs:{"color":_vm.wsATTENTION}},[_vm._v(" mdi-folder-outline ")]),_vm._v(" ... ")],1)])])]}}],null,true)}):_vm._e(),(!_vm.selectedFolder && !_vm.search && !_vm.MODULE_PERMISSIONS(['MODULE_STORAGE__FULL_ACCESS'] , true))?_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('tr',{on:{"click":function($event){_vm.openFolder({ uuid : 'root' , name : _vm.$t('file_browser.shared_folder')} , true)}}},[_c('td',{staticClass:"pointer",staticStyle:{"border-bottom":"1px solid var(--wsBACKGROUND)"},style:(("background : " + (hover ? _vm.wsLIGHTCARD : 'transparent') + ";")),attrs:{"colspan":colspanValue}},[_c('h5',[_c('v-icon',{attrs:{"color":_vm.wsACCENT}},[_vm._v(" mdi-folder-account ")]),_vm._v(" "+_vm._s(_vm.$t('file_browser.shared_folder'))+" ")],1)])])]}}],null,true)}):_vm._e()]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('h5',[_c('v-icon',{attrs:{"color":_vm.wsATTENTION}},[_vm._v(_vm._s(_vm.getIcon(item.mime)))]),_vm._v(" "+_vm._s(_vm.SHORTEN_FILE_NAME(item.name))+" ")],1)]}},{key:"item.size",fn:function(ref){
var item = ref.item;
return [_c('div',[(item.mime !== 'folder')?_c('h5',[_vm._v(_vm._s(_vm.PARSE_FILE_SIZE(item.size)))]):_vm._e()])]}},{key:"item.relations_count",fn:function(ref){
var item = ref.item;
return [_c('div',[(item.relations_count > 0)?_c('h5',{staticClass:"text-center"},[_vm._v(" "+_vm._s(item.relations_count))]):_vm._e()])]}},{key:"item.date_added",fn:function(ref){
var item = ref.item;
return [_c('h5',{staticClass:"font-weight-medium"},[_vm._v(" "+_vm._s(_vm.PARSE_DATE(item.date_added*1000 )))])]}}],null,false,1847650334)})],1)],2):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }